import { message } from "antd";
import axios from "axios";
// import mixpanel from "mixpanel-browser";

const URL = process.env.REACT_APP_DB_URL;

export const getFieldById = async (id, candidateId) => {
  try {
    var url = `${URL}/forms/fields/${id}/with_next/`;

    if (candidateId) {
      url += `?candidate_id=${candidateId}`;
    }
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log(err, "ERROR");
  }
};

export const candidateIssue = async (data) => {
  try {
    if (!data || !data.message) {
      message.error("Issue message shouldn't be empty");
      return;
    }
    const response = await axios.post(`${URL}/candidates/feedback/`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const telephonicAssessment = async (data) => {
  try {
    if (!data) {
      message.error("Issue message shouldn't be empty");
      return;
    }
    const response = await axios.post(`${URL}/candidates/telephonic-assessment/`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const logError = async (data) => {
  try {
    const response = await axios.post(`${URL}/core/log-error/`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const uploadFile = async (audioBlob, data, blurTime) => {
  console.log("UQD: ", data);
  const candidateResponse = {
    audio_blob: audioBlob,
    candidate_id: data?.candidateId ? data?.candidateId : null,
    form_id: data?.formId,
    field_id: data?.fieldId,
    job_id: data?.jobId,
    ruid: data?.ruId,
  };

  if (blurTime) {
    candidateResponse["blur_time"] = blurTime/1000;
  }

  let formData = new FormData();

  for (const key of Object.keys(candidateResponse)) {
    formData.append(key, candidateResponse[key]);
  }

  try {
    let api = "";
    if (data?.jobSlug)
      api = `${URL}/candidates/response/v2/${data?.jobSlug}/file-upload/`;
    else api = `${URL}/forms/uploadFile/`;
    const response = await axios.post(api, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    const issueData = {
      tag: "[uploadFile method (Audio) error]",
      message: `[Debugging] ${JSON.stringify(error?.response)} ${formData} ${
        error.stack
      }`,
      candidate: data?.candidateId || null,
      url: window.location.href,
      page_title: document.title,
      error_cause: error?.cause ? error?.cause : null,
      error_stack: error?.stack ? error?.stack : null,
    };
    // const res2 = await candidateIssue(issueData);
  }
};

export const trackUser = (tag, message, extra={}, callBack=null) => {
  var params = Object.fromEntries(new URLSearchParams(window?.location?.search));

  var tracking_data = {
    "tag": tag,
    "message": message,
    "current_url": window.location.href,
    ...params,
    ...extra
  }

  try {
    axios.post(`${URL}/core/byakugan/`, {logged_data: tracking_data}).then((res) => {
      console.log("Successfully reported log: ");
      console.log(tracking_data);

      if (callBack) {
        callBack();
      }
    }).catch((err) => {
      console.log("Error occured while reporting log: ");
      console.log(err);

      if (callBack) {
        callBack();
      }
    });
  } catch (error) {
    console.log("Error occured while reporting log: ");
    console.log(error);

    if (callBack) {
      callBack();
    }
  }
};

export const unloadListener = (e) => {
  console.log("Upload listener!!");
  // track action
  trackUser("Candidate Leaving page", "Candidate Leaving page");
  // Cancel the event
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = '';
}
