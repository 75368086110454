// troubleshoot

import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Collapse, Button, Input, Form, notification } from "antd";
import { candidateIssue } from "../../../services/candidateFormApi";

const { Panel } = Collapse;

const ReportModal = ({
  modalVisible,
  setModalVisible,
  responseCandidateId,
}) => {
  const [modalVisible2, setModalVisible2] = useState(false);
  const [issue, setIssue] = useState("");

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleOk2 = () => {
    setModalVisible2(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleCancel2 = () => {
    setModalVisible2(false);
  };

  const handleSubmit = () => {
    setModalVisible2(false);
  };

  const search = useLocation().search;

  const getQuery = (key, isInt = false) => {
    let val = new URLSearchParams(search).get(key);
    return isInt ? (val ? parseInt(val) : null) : val;
  };

  const handleSubmit2 = async () => {
    let extra_data = {
      candidate_id: getQuery("candidate_id", true),
      form_id: getQuery("form_id", true),
      job_id: getQuery("job_id", true),
      ruid: getQuery("ruid"),
      field_id: getQuery("field_id", true),
      fieldset_id: getQuery("fieldset_id", true),
      job_slug: getQuery("job_slug"),
    };

    const data = {
      message: issue,
      candidate: responseCandidateId ? responseCandidateId : null,
      extra_data: extra_data
    };
    const res = await candidateIssue(data);
    if (res) {
      notification["success"]({
        message: "Thanks for reporting the issue.",
        description: "We will get back to you as soon as possible.",
      });
      setModalVisible2(false);
    }
  };

  const handleReport = () => {
    setModalVisible2(true);
  };

  const panelData = [
    {
      header: "Recording button not working ?",
      content:
        "Make sure you have given the microphone access properly. Reload this page and follow the steps given in the welcome message carefully.",
    },
    {
      header: "Voice is not getting recorded properly, disturbance issues ?",
      content:
        "Most probably the microphone of your device is not working properly. Try using a different device to submit the response.",
    },
    {
      header: "Stop recording button is not working ?",
      content:
        "Your response should be longer then 20 seconds and therefore, stop recording button stays disabled until 20 seconds are completed.",
    },
    {
      header: "Page is getting stuck after clicking on Stop Recording button ?",
      content:
        "After 20 seconds are completed, the Stop recording button will be available, clicking on which will show a save button which on clicking will submit the response and move you to the next question, so you might feel stuck but the question has already changed, please read it and answer it accordingly.",
    },
  ];

  return (
    <>
      <Modal
        title="Having trouble submitting your response ?"
        visible={modalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" danger onClick={handleReport}>
            Report an issue
          </Button>,
        ]}
      >
        <Collapse bordered={false}>
          {panelData.map(({ header, content, index }) => (
            <>
              <Panel header={header} key={header + index}>
                <p>{content}</p>
              </Panel>
            </>
          ))}
        </Collapse>
      </Modal>
      <Modal
        title="Report your issue"
        visible={modalVisible2}
        onCancel={handleCancel2}
        footer={[
          <Button key="cancel" onClick={handleCancel2}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit2}>
            Submit
          </Button>,
        ]}
      >
        <p>Please explain the issue your are facing ?</p>
        <Form>
          <Input.TextArea
            rows={4}
            placeholder="Let us know what's going on..."
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
          />
        </Form>
        <br />
        {!responseCandidateId ? (
          <p style={{ fontWeight: 300 }}>
            Disclaimer: Please mention your email and contact details, so we can
            get to you as soon as possible.
          </p>
        ) : null}
      </Modal>
    </>
  );
};

export default ReportModal;
