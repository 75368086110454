import "./App.css";
import "./styles/antd.less";
import "./styles/antd-overrides.less";
import "antd/dist/antd.min.css";
import FormField from "./pages/FormField";
import AudioForm from "./pages/AudioForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { logError } from "./services/candidateFormApi";

function App() {
  useEffect(() => {
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_ID}`, {
      debug: true,
      ignore_dnt: true,
    });

    async function handleError(event) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const pathArray = window.location.pathname.split("/");
      const data = {
        tag: "[Frontend Error]",
        message: event?.message ? event?.message : null,
        extra_data: {
          stack: event?.error?.stack ? event?.error?.stack : null,
          lineNo: event?.lineno ? event?.lineno : null,
          colNo: event?.colno ? event?.colno : null,
          url: window.location.href ? window.location.href : null,
        },
        url: window.location.href ? window.location.href : null,
        page_title: document.title,
        candidate: params["candidate_id"]
          ? parseInt(params["candidate_id"])
          : null,
        form: params["form_id"] ? parseInt(params["form_id"]) : null,
        field: params["field_id"] ? parseInt(params["field_id"]) : null,
      };
      try {
        mixpanel.track("Common Error", {
          data: data,
          SearchParams: Object.fromEntries(
            new URLSearchParams(window.location.search)
          ),
        });
        logError(data);
      } catch (error) {
        console.log(error);
      }
    }

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/candidate/form" element={<FormField />} />
          <Route path="/candidate/audio/form" element={<AudioForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
