export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);

  return `${getMinutes} : ${getSeconds}`;
};

export const TimeToWords = (time) => {
  const seconds = time % 60;
  const minutes = Math.floor(time / 60);

  return `${minutes ? `${minutes} minutes` : ""}${
    minutes && seconds ? " " : ""
  }${seconds ? `${seconds} seconds` : ""}`;
};

export const visualize = (audioStream, canvasRef) => {
  try {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");

    let audioCtx;
    audioCtx = new AudioContext();

    const source = audioCtx.createMediaStreamSource(audioStream);

    const analyserNode = audioCtx.createAnalyser();
    analyserNode.fftSize = 2048;
    const bufferLength = analyserNode.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyserNode);
    // setAnalyser(analyserNode);

    const draw = () => {
      if (canvas) {
        const { width, height } = canvas;

        requestAnimationFrame(draw);

        analyserNode.getByteTimeDomainData(dataArray);

        canvasCtx.clearRect(0, 0, width, height);

        canvasCtx.lineWidth = 2;
        canvasCtx.strokeStyle = "#ef4747";

        canvasCtx.beginPath();

        let sliceWidth = (width * 1.0) / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          let v = dataArray[i] / 128.0;
          let y = (v * height) / 2;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        canvasCtx.lineTo(width, canvas.height / 2);
        canvasCtx.stroke();
      }
    };
    draw();
  } catch (error) {
    console.log(error);
  }
};

export const checkMicPermissionState = async (setMicState) => {
  try {
    const status = await navigator.permissions.query({
      name: "microphone",
    });
    switch (status.state) {
      case "granted":
        setMicState("granted");
        break;
      case "prompt":
        setMicState("prompt");
        break;
      case "denied":
        setMicState("denied");
        break;
    }
    status.onchange = () => {
      switch (status.state) {
        case "granted":
          setMicState("granted");
          break;
        case "denied":
          setMicState("denied");
          break;
      }
    };
  } catch (err) {
    console.log(err);
  }
};

export function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const getNextFieldurl = (nextField, formData) => {
  if (nextField) {
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    const urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    console.log("going to next field");
    params.field_id = nextField;
    params.candidate_id = formData.candidateId;
    params =
      "?" +
      Object.keys(params)
        .map((key) => {
          return `${key}=${encodeURIComponent(params[key])}`;
        })
        .join("&");
    return origin + pathname + params;
  } else {
    console.log("reached end");
    console.log(formData);
    let nextFieldset = formData.fieldsetId + 1;
    let formId = formData.formId;
    let jobSlug = formData?.jobSlug;
    let candidateId = formData.candidateId;
    console.log(nextFieldset);

    let newUrl;

    if (jobSlug) {
      newUrl = `${process.env.REACT_APP_FE_URL}/candidate/jobform/${jobSlug}?candidate_id=${candidateId}&form_id=${formId}&fieldset_id=${nextFieldset}`;
    } else {
      newUrl = `${process.env.REACT_APP_FE_URL}/candidate/form/${formId}?candidate_id=${candidateId}&fieldset_id=${nextFieldset}`;
    }
    console.log("New Url: ", newUrl);
    return newUrl;
  }
};

export const toMilliseconds = (hrs, min, sec) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000;
