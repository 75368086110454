import React, { useState, useEffect } from "react";
import { Modal, Collapse, Button, Form, Input, notification } from "antd";
import { candidateIssue, telephonicAssessment } from "../../../services/candidateFormApi";
import { trackUser } from "../../../services/candidateFormApi";

const { Panel } = Collapse;

const panelData = [
    {
      header: "'Start Recording' button not working?",
      content: "Make sure you have given the microphone access properly. Reload this page and follow the steps given in the welcome message carefully.",
    },
    {
        header: "'Stop recording' button is not working?",
        content: "Your audio responses should be longer than 20 seconds, the 'STOP Recording' button stays disabled until that condition is not full filled.",
    },
    {
        header: "Voice is not getting recorded properly?",
        content:
            "Please move to a quiter environment and in case the issue is with your microphone, you can also copy the link of this page and open it in a different device."
    }
];


export default function IssuesModal({ openIssueModal, setOpenIssueModal, queryParam, issueOccured, setIssueOccured }) {
    var random = "We suggest, if possible, to open the website in chrome browser (Latest version) and on a desktop or laptop (intead of a mobile device)";

    const [otpedTelephony, setOptedTelephony] = useState(false);
    const [callBackNumber, setCallBackNumber] = useState("08069458485");

    const [issue, setIssue] = useState(null);


    const optTelephonicAssessment = () => {
        if (!queryParam) return;

        trackUser("Opted for Telephonic Assessment", "Opted for Telephonic Assessment");
        
        setOptedTelephony(true);
        telephonicAssessment(
            {job_id: queryParam?.jobId, candidate_id: queryParam?.candidateId}
        ).then((res) => {
            console.log(res?.data);
            if (res?.data && res?.data?.callback_number) {
                setCallBackNumber(res?.data?.callback_number);
            }
        });
    }

    const submitFeedBack = () => {
        if (!queryParam) return;
        const data = {
            message: issue,
            candidate: queryParam?.candidateId,
            extra_data: queryParam
        };
        
        candidateIssue(data).then((res) => {
            trackUser("Submitted Issue", "Submitted Issue", data);
            setOpenIssueModal({ visible: false});
            notification["success"]({
                message: "Thanks for reporting the issue.",
                description: "We will get back to you as soon as possible.",
            });
        });
    }

    useEffect(() => {
        if (openIssueModal?.visible && !issueOccured)
            setIssueOccured(true);
    }, [openIssueModal])


    return (
        <>
            <Modal 
                title="Are you having any issues while submitting your audio?" 
                open={openIssueModal?.visible}
                onCancel={() => setOpenIssueModal({ visible: false})}
                footer={
                    <div style={{display: "flex", justifyContent: "center"}}>
                        { otpedTelephony ? 
                            (
                            <Button style={{minWidth: "60%"}} type="primary" onClick={submitFeedBack}>
                                Submit
                            </Button>):
                            (
                            <Button style={{minWidth: "60%"}} type="primary" onClick={() => setOpenIssueModal({ visible: false})}>
                                Close
                            </Button>)
                        }
                    </div>
                }
            >
                {
                    otpedTelephony ?
                        (
                            <>
                                <div>
                                    You will be receiving a phone call from "{callBackNumber}" number, in the next 5 mins. 
                                    Please listen to the instructions giving in the phone call carefully before answering.
                                </div>
                                <div>
                                    In the meantime, you can report the issue you have faced, while filling up this form, below. 
                                    We request you to be as detailed about the issue as possible, to help us make this platform 
                                    better for you and other candidates like you.
                                </div>
                                <Form>
                                    <Input.TextArea
                                    rows={4}
                                    placeholder="Please explain your issue in details and also mention from email, name and contact info, along with your issue.."
                                    value={issue}
                                    onChange={(e) => setIssue(e.target.value)}
                                    />
                                </Form>
                            </>
                        ) : (
                            <>
                                <p>Here are some of the <strong>commonly faced issue</strong> and possible ways you can resolve them:</p>

                                <div>
                                    {panelData?.map((item, idx) => {
                                        return <Collapse collapsible="header" defaultActiveKey={['0']}>
                                            <Panel header={item.header} key={idx}>
                                                <p>{item.content}</p>
                                            </Panel>
                                        </Collapse>
                                    })}
                                </div>

                                <div style={{margin: "10px"}}>
                                    In case, you can't find the issue in the above list or the given solution doesn't fix your issue, 
                                    you can copy the link and <strong>continue</strong> with the rest of the assessment process <strong>
                                    on a different device</strong>.

                                    <br/>
                                    In case the issue is still present, <strong>please report your issue at info@aviate.jobs</strong>.
                                    (Please make sure to include a detailed discription of the issue you are facing, 
                                    along with screenshots/screen recordings.)
                                </div>
                            </>
                        )
                }
                
            </Modal>
        </>);
}
