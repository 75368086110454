import React, { useEffect, useRef, useState } from "react";
import { Button, message, notification, Tooltip } from "antd";
import {
  checkMicPermissionState,
  formatTime,
  visualize,
} from "../../utils/functions";
import "../../styles/audioRecorder.css";
import ButtonControls from "./ButtonControls";
import RecorderIcon from "./RecorderIcon";
import ReportModal from "./modals/ReportModal";
import CustomModal from "./modals/CustomModal";
import IssueModal from "./modals/IssueModal";
import mixpanel from "mixpanel-browser";
import { logError } from "../../services/candidateFormApi";

function AudioRecorder({ submitResponse, data, fieldCompleted }) {
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const [micState, setMicState] = useState("");
  const [chunks, setChunks] = useState([]);
  const [voiceRecorder, setVoiceRecorder] = useState();
  const [audioStream, setAudioStream] = useState();
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const playerRef = useRef(null);
  const countRef = useRef(null);
  const canvasRef = useRef(null);
  const [issueModal, setIssueModal] = useState({ visible: false, issue: "" });
  const inactiveTimer = useRef();
  const inactiveTime = 20000;
  const clickLimit = 5;
  const [clickCount, setClickCount] = useState({
    startRecording: 0,
    stopRecording: 0,
    facingIssues: 0,
  });

  // ============ Code for show issue modal if user stays inactive for long time =============

  useEffect(() => {
    // For creating a timeout for inactivity
    inactiveTimer.current = setTimeout(() => {
      console.log("inactivity found");
      setIssueModal({ visible: true, issue: "inactivity" });
    }, inactiveTime);

    return () => {
      clearTimeout(inactiveTimer.current);
    };
  }, []);

  // Run when some activity is detected
  const activityDetect = () => {
    // Timer created for showing issue modal is killed
    clearTimeout(inactiveTimer.current);
  };
  // ================================================================================

  // ======== Code for showing issue modal if user clicks on a button several times =============

  // For counting no of clicks on each button
  const clickCounter = (btn) => {
    let temp = clickCount;
    temp[btn] += 1;

    console.log("clicked");

    // True if no of clicks on any of the buttons exceed the clickLimit
    const clickLimitExceeded = Object.values(temp).some(
      (cnt) => cnt > clickLimit
    );

    if (clickLimitExceeded) {
      // Issue feedback modal is shown
      setIssueModal({ visible: true, issue: "multiple_click" });
      // Reseting click count, so that modal is not shown immediately after shown once
      Object.keys(temp).forEach((btn) => {
        temp[btn] = 0;
      });
    }
    // Updating the clickCount state
    setClickCount(temp);
  };

  // =======================================================================================

  // ============= Code to show issue modal if user tries to quit/ reload the webpage =============

  function issueQuery(e) {
    if (fieldCompleted) return;
    e.preventDefault();
    e.returnValue = "";
    // To avoid clashes between error, killing the timer for inactivity
    activityDetect();
    // Show issue modal if user tries to quit
    setIssueModal({ visible: true, issue: "try_to_quit" });
  }
  useEffect(() => {
    window.addEventListener("beforeunload", issueQuery, { capture: true });
    return () => {
      window.removeEventListener("beforeunload", issueQuery, { capture: true });
    };
  }, [fieldCompleted]);

  // ==============================================================================

  useEffect(() => {
    checkMicPermissionState(setMicState);
  }, []);

  useEffect(() => {
    handleTimerReset();
  }, [data]);

  useEffect(() => {
    if (voiceRecorder) {
      voiceRecorder.onstop = async (e) => {
        // console.log("Data Available after MediaRecorder.stop() is called.");
        if (chunks.length === 0) {
          message.error("Error occured while uploading, empty file found");
          //   setStopLoading(false);
          //   setRetry(true);
          return;
        }

        try {
          const blob = new Blob(chunks, { type: "audio/wav" });
          blob.name = "audio";
          blob.lastModified = new Date();
          setChunks([]);
          submitResponse(blob);
        } catch (err) {
          console.log(err);
        }
      };
      voiceRecorder.ondataavailable = ({ data }) => {
        chunks.push(data);
      };
    }
  }, [voiceRecorder, playerRef, chunks]);

  const handleTimerStart = () => {
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleTimerStop = () => {
    clearInterval(countRef.current);
  };

  const handleTimerReset = () => {
    clearInterval(countRef.current);
    setTimer(0);
  };

  const handleStartRecording = async () => {
    activityDetect();
    clickCounter("startRecording");
    handleTimerReset();
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const mediaRecorder = new MediaRecorder(audioStream);

      visualize(audioStream, canvasRef);
      mediaRecorder.start();
      // console.log("Recorder started..");
      handleTimerStart();

      setAudioStream(audioStream);
      setVoiceRecorder(mediaRecorder);
      setIsRecording(true);

      // Tracking the users who clicked start recording button
      const mixdata = {
        tag: "[Start Recording Clicked(Audio)]",
        message: "Start Recording button clicked.",
        extra_data: {
          url: window.location.href,
          page_title: document.title,
        },
        candidate: data?.candidateId ? data?.candidateId : null,
        form: data?.formId ? data.formId : null,
        field: data?.fieldId ? data?.fieldId : null,
      };
      try {
        mixpanel.track("Recording Clicked", {
          data: mixdata,
          SearchParams: Object.fromEntries(
            new URLSearchParams(window.location.search)
          ),
        });
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
      const data = {
        tag: "[Start Recording Error]",
        message: error?.message ? error?.message : null,
        url: window.location.href,
        page_title: document.title,
        error_cause: error?.cause ? error?.cause : null,
        error_stack: error?.stack ? error?.stack : null,
      };
      try {
        mixpanel.track("Start Recording Error", {
          data: data,
          SearchParams: Object.fromEntries(
            new URLSearchParams(window.location.search)
          ),
        });
        logError(data);
      } catch (error) {
        console.log(error);
      }
      window.alert("We are having trouble starting the audio recording. This maybe happening because your \
                    browser is not able to find your device's Mic or your browser version doesn't support audio \
                    recording. We suggest you to check your device permissions \
                    or copy the url and try submitting the form on a different devices.");
    }
  };

  const handleStopRecording = () => {
    activityDetect();
    clickCounter("stopRecording");
    try {
      if (!isRecording || !audioStream || !voiceRecorder) return;

      const tracks = audioStream.getAudioTracks();

      tracks.forEach((track) => track.stop());

      voiceRecorder.stop();

      handleTimerStop();

      setAudioStream(undefined);
      setVoiceRecorder(null);
      setIsRecording(false);
      //   setStopLoading(true);
    } catch (error) {
      console.log(error);
      const data = {
        tag: "[Stop Recording Error]",
        message: error?.message ? error?.message : null,
        url: window.location.href,
        page_title: document.title,
        error_cause: error?.cause ? error?.cause : null,
        error_stack: error?.stack ? error?.stack : null,
      };
      try {
        mixpanel.track("Stop Recording Error", {
          data: data,
          SearchParams: Object.fromEntries(
            new URLSearchParams(window.location.search)
          ),
        });
        logError(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const showReportModal = () => {
    activityDetect();
    clickCounter("facingIssues");
    setReportModalVisible(true);
  };

  return (
    <>
      <canvas className="canvas" ref={canvasRef} />
      <div className="timer">
        <strong>{formatTime(timer)}</strong>
        <RecorderIcon isRecording={isRecording} />
      </div>
      <div className="audio" style={{ display: "none" }}>
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <audio id="player" ref={playerRef} autoPlay={false} controls />
        </span>
      </div>
      <ButtonControls
        timer={timer}
        isRecording={isRecording}
        handleStartRecording={handleStartRecording}
        handleStopRecording={handleStopRecording}
        showReportModal={showReportModal}
        data={data}
      />

      {micState === "prompt" && <CustomModal modalNo={1} />}
      {micState === "denied" && <CustomModal modalNo={2} />}
      {micState === "granted" && <CustomModal modalNo={3} />}
      <ReportModal
        modalVisible={reportModalVisible}
        setModalVisible={setReportModalVisible}
      />
      <IssueModal
        modalVisible={issueModal}
        setModalVisible={setIssueModal}
        responseCandidateId={data?.candidateId}
      />
    </>
  );
}

export default AudioRecorder;
