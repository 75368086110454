import React from "react";
import Icon, { AudioOutlined, AudioMutedOutlined } from "@ant-design/icons";

const RecorderIcon = ({ isRecording }) => {
  return (
    <>
      {isRecording ? (
        <Icon
          component={AudioOutlined}
          className="mic_icon"
          style={{ color: "#52c41a" }}
        />
      ) : (
        <Icon component={AudioMutedOutlined} className="mic_icon" />
      )}
    </>
  );
};

export default RecorderIcon;
