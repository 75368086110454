import React, { useEffect, useState } from "react";
import AudioRecorder from "./AudioRecorder";
import Icon, { CaretRightFilled } from "@ant-design/icons";
import { message } from "antd";
import "../../styles/audioResponse.css";
import {
  getFieldById,
  logError,
  uploadFile,
} from "../../services/candidateFormApi";
import Loader from "../Loader";
import { getNextFieldurl, isEmpty } from "../../utils/functions";
import mixpanel from "mixpanel-browser";

function AudioResponse({ data, setData }) {
  const [fieldData, setFieldData] = useState({});
  const [fieldCompleted, setFieldCompleted] = useState(false);
  const [tabInactive, setTabInactive] = useState(false);

  // inactive timer
  const [startTimer, setStartTimer] = useState(null);
  const [blurTime, setBlurTime] = useState(0);

  window.addEventListener("visibilitychange", () => {
    setTabInactive(!tabInactive);
  });

  useEffect(() => {
    if (tabInactive) {
      // if tab is inActive start timer
      setStartTimer(Date.now());
    } else if (startTimer) {
      // if tab is active start timer
      setBlurTime(blurTime + (Date.now() - startTimer));
    }
  }, [tabInactive])

  useEffect(() => {
    if (!isEmpty(data))
      getFieldById(data.fieldId, data.candidateId).then((res) => {
        console.log(res);
        setFieldData(res);
      });
    setFieldCompleted(false);
  }, [data]);

  const goToNextField = () => {
    console.log("going to next field");
    let nextField = fieldData.next_field;
    console.log(nextField);

    let nextUrl = getNextFieldurl(nextField, data);
    console.log(nextUrl);
    window.location.replace(nextUrl);
  };

  useEffect(() => {
    if (fieldCompleted) goToNextField();
  }, [fieldCompleted]);

  const submitResponse = async (audioBlob) => {
    try {
      if (audioBlob?.size === 0) {
        message.error(
          "Invalid file size, please upload a file of length greater than 20s"
        );
        return;
      }

      let res = await uploadFile(audioBlob, data, blurTime);
      if (res?.status !== 200) {
        // retry uploading the file
        res = await uploadFile(audioBlob, data, blurTime);

        if (res?.status !== 200) {
          message.error("Something went wrong while upload, please retry");
          return;
        }
      }
      if (res?.status === 200) {
        // Tracking the form submission
        if (res?.data.candidate_id) {
          setData((data) => ({ ...data, candidateId: res.data.candidate_id }));
        }
        const mixdata = {
          tag: "[Recording Submitted]",
          message: "Recording submitted in microservice",
          extra_data: {
            url: window.location.href,
            page_title: document.title,
          },
          candidate: data?.candidateId ? data?.candidateId : null,
          form: data?.formId ? data.formId : null,
          field: data?.fieldId ? data?.fieldId : null,
        };
        try {
          mixpanel.track("Recording Submitted", {
            data: mixdata,
            SearchParams: Object.fromEntries(
              new URLSearchParams(window.location.search)
            ),
          });
        } catch (error) {
          console.log(error);
        }
        console.log("completed response");
        setFieldCompleted(true);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong while upload, please retry");
      const mixdata = {
        tag: "[Audio error]",
        message: "Error occured while uploading recording",
        url: window.location.href,
        page_title: document.title,
        error_cause: error?.cause ? error?.cause : null,
        error_stack: error?.stack ? error?.stack : null,
        candidate: data?.candidateId ? data?.candidateId : null,
      };
      try {
        mixpanel.track("Recording Upload Error", {
          data: mixdata,
          SearchParams: Object.fromEntries(
            new URLSearchParams(window.location.search)
          ),
        });
        logError(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (isEmpty(fieldData)) return <Loader />;

  return (
    <div className="container">
      <div className="center_form">
        <div className="title">
          <span className="count">
            {fieldData? fieldData?.sequence_number:"1"}
            <Icon component={CaretRightFilled} />
          </span>
          <h2>
            <span
              className="label"
              // className={
              //   steps[currentStep]?.invigilation_enabled
              //     ? "label block-selection"
              //     : "label"
              // }
            >
              {`${fieldData?.label} ${fieldData?.is_required ? "*" : ""}`}
            </span>
          </h2>
        </div>
        <p
          className="subtext"
          // className={
          //   steps[currentStep]?.invigilation_enabled
          //     ? "subtext block-selection"
          //     : "subtext"
          // }
        >
          {fieldData?.sub_text}
        </p>
        <AudioRecorder
          submitResponse={submitResponse}
          data={data}
          fieldCompleted={fieldCompleted}
        />
      </div>
    </div>
  );
}

export default AudioResponse;
