import React, { useEffect, useRef, useState } from "react";

export default function PreRecordTimer({ startRecording, showWelcomeModal, prestartTimer }) {
  const startTime = prestartTimer || 5;
  const intervalRef = useRef(null);
  const counterRef = useRef(startTime);

  const [preTimer, setPreTimer] = useState(startTime);

  useEffect(() => {
    if (intervalRef.current || showWelcomeModal) return;
    intervalRef.current = setInterval(function printTime(){
      console.log(counterRef.current);
      counterRef.current -= 1

      setPreTimer(counterRef.current);

      if (counterRef.current <= 0) {
        clearInterval(intervalRef.current);
        startRecording();
      }
    }, 1000);
  }, [showWelcomeModal]);

  return (
    <>
      <div style={{textAlign: "center", fontSize: "1.6rem"}}>
        <span>Read the question carefully.</span><br/>
        The recording will starts in{" "}<br />
        <span style={{fontSize: "2.1rem", fontWeight: "bold"}}>
          {preTimer} secs
        </span>
      </div>
    </>
  );
}
