import React, { useEffect, useState, useRef } from "react";
import "../styles/audioForm.css";
import Loader from "../components/Loader";
import aviateLogo from "../assets/aviate-logo.png";
import AudioRecorder from "../components/AudioForm/AudioRecorder";
import Question from "../components/AudioForm/Question";
import WelcomeModal from "../components/AudioForm/modals/WelcomeModal";
import IssuesModal from "../components/AudioForm/modals/IssuesModal";
import IssueTracker from "../components/AudioForm/IssueTracker";
import { getFieldById, uploadFile } from "../services/candidateFormApi";
import mixpanel from "mixpanel-browser";
import { trackUser, unloadListener } from "../services/candidateFormApi";

/**
 * Planing for the page
 * - First start with adding static question to the page along with aviate logo.
 * - Add method to submit the audio file to the backend
 * - Add a recorder component.
 * - Add API call to fetch the question.
 */


export default function AudioForm() {
    const [audioBlob, setAudioBlog] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [queryParam, setQueryParam] = useState(null);
    const [questionData, setQuestionData] = useState(null);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [openIssueModal, setOpenIssueModal] = useState(false);
    const [isRecording, setIsRecording] = useState(false);

    // Question time thresholds and countdowns
    const [minResponseTime, setMinResponseTime] = useState(20);
    const [maxResponseTime, setMaxResponseTime] = useState(60);
    const [prestartTimer, setPrestartTimer] = useState(5);

    // Question visible: If the welcome modal is closed.
    const [showWelcomeModal, setShowWelcomeModal] = useState(true);
    
    // track auto opening of issue modal, make sure once opened, it doesn't
    // open again and again.
    const [issueOccured, setIssueOccured] = useState(false);

    // Is the tab active (if candidate has left the page)
    const [tabInactive, setTabInactive] = useState(false);

    const [nextUrl, setNextUrl] = useState(null);

    const trackInterval = useRef();
    const defaultState = "Idle";
    const currentStateRef = useRef(defaultState);
    const location = window.location;

    // Adding visibility change event listener to the page.
    window.addEventListener("visibilitychange", () => {
        setTabInactive(!tabInactive);
    });

    useEffect(() => {
        if (tabInactive) {
            currentStateRef.current = "Inactive";
        } else if (isRecording) {
            currentStateRef.current = "Recording";
        } else {
            currentStateRef.current = "Idle";
        }
        console.log("Current User state: ", currentStateRef.current);
    }, [tabInactive, isRecording])

    useEffect(()=> {
        if (!trackInterval.current)
        trackInterval.current = setInterval(observeUser, 10000);
    }, []);

    // reporting to mixpanel
    const reportToMixpanel = (data, params=null) => {
        // Possible data options:
        // - Audio Form visit
        // - Success Mic Permission
        // - Error in Mic Permission
        // - Error in Audio Upload

        try {
            if (!params)
                params = queryParam

            var mixdata = {
                tag: `[${data}]`,
                message: data + " " + params?.candidateId,
                extra_data: {
                    url: window.location.href,
                    page_title: document.title,
                },
                candidate: params?.candidateId ? params?.candidateId : null,
                form: params?.formId ? params.formId : null,
                field: params?.fieldId ? params?.fieldId : null,
            }

            console.log("MIXPANEL");
            console.log(data, mixdata);
            console.log(mixpanel);

            mixpanel.track(data, {
                data: mixdata,
                SearchParams: Object.fromEntries(
                    new URLSearchParams(window.location.search)
                ),
            });    
        } catch (e) {
            console.log("Mixpanel error", e);
        }
            
    }

    const observeUser = () => {
        console.log(currentStateRef.current, "<>");
        var tag = "User State: " + currentStateRef.current;
        trackUser(tag, tag);
    }

    // Fetch data from query params
    useEffect(() => {
        if (location.search && !queryParam) {
            let searchParams = new URLSearchParams(location.search);
            let data = {
                candidateId: parseInt(searchParams.get("candidate_id")),
                formId: parseInt(searchParams.get("form_id")),
                jobId: parseInt(searchParams.get("job_id")),
                ruId: searchParams.get("ruid"),
                fieldId: parseInt(searchParams.get("field_id")),
                fieldsetId: parseInt(searchParams.get("fieldset_id")),
                jobSlug: searchParams.get("job_slug"),
                skipWelcome: searchParams.get("skipWelcome"),
            }
            console.log(data);
            setQueryParam(data);
    
            reportToMixpanel("Audio Form visit", data);
        }
    }, []);

    // calling get question api to fetch details about the question
    useEffect(() => {
        if (!queryParam?.fieldId) return;
        trackUser("Fetching question data", "Fetching question data");

        getFieldById(queryParam.fieldId, queryParam.candidateId).then((res) => {
            if (!res?.id) {
                var nextPageURL = fetchNextPageURL(res?.next_field, false);
                console.log("****** Skipping to ", nextPageURL);

                if (nextPageURL) {
                    if (res?.next_field) {
                        trackUser(
                            "Skipping to next question", 
                            "Skipping to next question", 
                            {"redirection_url": nextPageURL},
                            () => window.location.replace(nextPageURL));
                    } else {
                        trackUser(
                            "Skipping to main", 
                            "Skipping to back main app", 
                            {"redirection_url": nextPageURL},
                            () => window.location.replace(nextPageURL));
                    }
                }
            }

            setMinResponseTime(res?.min_response_time);
            setMaxResponseTime(res?.max_response_time);
            setPrestartTimer(res?.prestart_timer);
            setQuestionData(res);
            trackUser("Fetched question data", "Fetched question data", {"question_data": res});
        });
    }, [queryParam]);

    const fetchNextPageURL = (next_field_id, skipWelcome=true) => {
        if (next_field_id) {
            console.log(location);
            var url = new URL(location.origin + location.pathname);
            var newParams = new URLSearchParams(location.search);;

            newParams.set("field_id", next_field_id);

            if (skipWelcome) {
                newParams.set("skip_welcome", "yes");
            }
            console.log(newParams);
            url.search = new URLSearchParams(newParams);

            var next_url = url.toString();

            console.log(next_url);
            return next_url;
        } else {
            var params = new URLSearchParams(location.search);

            let nextFieldset;
            try {
                nextFieldset = parseInt(params.get("fieldset_id")) + 1;
            } catch (err) {
                nextFieldset = 1;
            }
                
            let formId = params.get("form_id");
            let jobSlug = params.get("job_slug");
            let candidateId = params.get("candidate_id");

            let newUrl;

            if (jobSlug) {
                newUrl = `${process.env.REACT_APP_FE_URL}/candidate/jobform/${jobSlug}?candidate_id=${candidateId}&form_id=${formId}&fieldset_id=${nextFieldset}`;
            } else {
               newUrl = `${process.env.REACT_APP_FE_URL}/candidate/form/${formId}?candidate_id=${candidateId}&fieldset_id=${nextFieldset}`;
            }

            console.log("New Url: ", newUrl);
            return newUrl;
        }

    }

    // create the next page url
    useEffect(() => {
        if (!questionData) return;
        console.log(questionData);
        setNextUrl(fetchNextPageURL(questionData?.next_field));
    }, [questionData]);

    // once audioBlob value is set, push the audioBlob to backend
    useEffect(() => {
        if (!audioBlob) return;

        console.log("Question Data: ", questionData);

        const uploadBlob = async () => {
            setUploadingFile(true);
            var res = await uploadFile(audioBlob, queryParam);
            console.log(res);
            trackUser("Audio file uploaded", "Audio file uploaded");

            // remove before unload listerner, to allow user to get redirected
            window.removeEventListener('beforeunload', unloadListener);

            // remove observe user listener
            clearInterval(trackInterval.current);

            // Move to the next question
            if (nextUrl) {
                if (questionData?.next_field) {
                    trackUser(
                        "Redirection to next question", 
                        "Redirecting to next question", 
                        {"redirection_url": nextUrl},
                        () => window.location.replace(nextUrl));
                } else {
                    trackUser(
                        "Redirection to main", 
                        "Redirecting to back main app", 
                        {"redirection_url": nextUrl},
                        () => window.location.replace(nextUrl));
                }
            }
        }

        uploadBlob().catch((error) => {
            console.log(error);
            trackUser(
                "Audio file uploaded error", 
                "Error occured while uploading audio file", 
                {"error": error});
        });
        setAudioUrl(URL.createObjectURL(audioBlob));
    }, [audioBlob]);

    useEffect(() => {
        console.log("YYYYY", showWelcomeModal);
    }, [showWelcomeModal]);

    if (!questionData) return <Loader />;
    
    return (
        <>
            <div className="container">
                <div className="qrate-logo">
                    <img src={aviateLogo} alt="" />
                </div>

                <div>
                    {/* Display question */}
                    <Question questionData={questionData} />

                    {/* Audio recorder */}
                    <AudioRecorder 
                        setAudioBlog={setAudioBlog} 
                        uploadingFile={uploadingFile} 
                        setOpenIssueModal={setOpenIssueModal}
                        isRecording={isRecording}
                        setIsRecording={setIsRecording}
                        reportToMixpanel={reportToMixpanel}
                        showWelcomeModal={showWelcomeModal}
                        minResponseTime={minResponseTime}
                        maxResponseTime={maxResponseTime}
                        prestartTimer={prestartTimer}
                    />
                </div>

                <div></div>
            </div>
            <WelcomeModal 
                reportToMixpanel={reportToMixpanel} 
                showWelcomeModal={showWelcomeModal} 
                setShowWelcomeModal={setShowWelcomeModal} 
            />
            
            <IssuesModal 
                openIssueModal={openIssueModal} 
                setOpenIssueModal={setOpenIssueModal}
                isRecording={isRecording} 
                queryParam={queryParam}
                issueOccured={issueOccured}
                setIssueOccured={setIssueOccured}
            />
            <IssueTracker 
                setOpenIssueModal={setOpenIssueModal} 
                isRecording={isRecording}
                issueOccured={issueOccured}
                setIssueOccured={setIssueOccured}
            />
        </>
    );
}
