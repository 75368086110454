import React, { useState } from "react";
import { Modal, Button, Tooltip, Image } from "antd";
import micAllow from "../../../assets/mic-allow.png";
import audioCard2 from "../../../assets/audio-card-2.png";
import audioCard3 from "../../../assets/audio-card-3.png";
import "../../../styles/audioForm.css";
import { trackUser } from "../../../services/candidateFormApi";

function Step1({ onclick }) {
    return (
        <div style={{fontSize: "1.1rem", textAlign: "center",}}>
            <h3>To finish your interview registration, you need to submit an audio answer to 4 questions within the next 4 minutes.</h3>
        </div>
    );
}

function Step2({ onclick }) {
    return (
        <div style={{fontSize: "1.1rem", textAlign: "center",}}>

            <h3>You'd have 5 seconds to read the question and 60 seconds to answer each question.</h3>
            <h3 style={{fontWeight: "bold"}}> You need to start speaking your answer when you see the countdown timer.</h3>

            <Tooltip placement="right" title="Preview">
                <Image
                    preview={{ imageVisible: false }}
                    src={audioCard2}
                    style={{ cursor: "pointer", maxWidth: "500px" }}
                />
            </Tooltip>
        </div>
    );
}

function Step3({ onclick }) {
    return (
        <div style={{fontSize: "1.1rem", textAlign: "center",}}>

            <h3>When you're done answering the question, click on the stop recording button. The recording must be atleast 20 sec long.</h3>

            <Tooltip placement="right" title="Preview">
                <Image
                    preview={{ imageVisible: false }}
                    src={audioCard3}
                    style={{ cursor: "pointer", maxWidth: "500px" }}
                />
            </Tooltip>

            <h3 style={{fontWeight: "bold"}}>
                If your answer is longer than 60 seconds, you'd automatically be moved to the next question.
            </h3>
        </div>
    );
}

function Step4({ onclick }) {
    return (
        <div style={{fontSize: "1.1rem", textAlign: "center",}}>

            <h3 style={{fontWeight: "bold"}}>
                YOU NEED TO FINISH ALL 4 ANSWERS <span style={{color: "red"}}>IN ONE GO</span>.
            </h3>

            <h3>DON'T MINIMIZE THIS WINDOW, RELOAD THIS PAGE, SWITCH THIS TAB, CLOSE THIS PAGE etc.</h3>

            <h3 style={{fontWeight: "bold"}}>
                IF YOU MOVE AWAY FROM THIS TAB/SCREEN IN THE NEXT 4 MINUTES, YOUR INTERVIEW REGISTRATION AND SPEECH ASSESSMENT MIGHT GET <span style={{color: "red"}}>REJECTED</span>.
            </h3>
        </div>
    );
}

function Step5({ onclick, permissionDenied }) {
    return (
        <div style={{fontSize: "1.1rem", textAlign: "center",}}>

            <h3>
                You need to provide <span style={{ color: "red"}}> microphone access </span> 
                to proceed.
            </h3>

            <h3 style={{fontWeight: "bold"}}>How to allow microphone access in Chrome?</h3>

            {
                (permissionDenied) ? 
                    (<>
                        <h3>
                        Your browser has <strong style={{color: "red"}}>blocked</strong> this website from getting the microphone access. 
                        As stated before audio assessment is a mandatory step in your application and mic access is required for it. 
                        <strong> Please go through the following video to enable the access.</strong>
                        </h3>

                        <div style={{textAlign: "center"}}>
                            <video className="permission-video" width="400" controls>
                                <source src="https://dev-qrate-storage.s3.ap-south-1.amazonaws.com/extra/Screen+Recording+2023-06-03+at+7.02.05+AM.mp4" type="video/mp4"/>
                                Your browser does not support HTML video.
                            </video>
                        </div>
                    </>) : 
                    (<>
                        <h3>
                            Click on the "<strong style={{color: "red"}}>Allow Access</strong>" button below, 
                            and you will see a prompt from your browser about Microphone access. Click <strong>Allow</strong> to 
                            give this website the microphone access.
                        </h3>

                        <Tooltip placement="right" title="Preview">
                            <Image
                                preview={{ imageVisible: false }}
                                // width="100%"
                                src={micAllow}
                                // onClick={() => setImageVisible(true)}
                                style={{ cursor: "pointer", maxWidth: "500px" }}
                            />
                        </Tooltip>
                    </>)
            }
        </div>
    );
}

function WelcomeMsg() {
    return (
        <div style={{fontSize: "1.1rem", textAlign: "center",}}>
            <div>
                <strong>In this round you'd need to submit your audio response.</strong> Please note that
                this is a <strong>mandatory</strong> step and your application won't be considered
                <strong> without your audio responses</strong>. 
            </div>
            <br />
            <ul style={{textAlign: "left"}}>
                <li>You will be given <strong>4 question</strong> one by one and you have to <strong>speak your answer out loud</strong>.</li>
                <li>For each question, you will be given <strong>5 secs to read the question after which the 
                voice recorder will automatically start recording your voice</strong>.</li>
                <li>Once your answer is complete click on <strong>"Stop Recording"</strong> button to upload your response and move to next question.</li>
                <li>You need to complete this assessment within the next 8 mins, (that is you will be given <strong>2 min to answer each question</strong>).</li>
                <li>Generally, each answers is 30 secs long, so <strong>you can complete the entire assessment in around 2 min.</strong></li>
                <li style={{ color: "red" }}>This assessment is proctored, which means your job application may get <strong>Rejected</strong> if you change tabs during the assessment, take too long to answers your question or copy your answer from somewhere.</li>
            </ul>
        </div>
    );
}

function MicAccess({ permissionDenied }) {
    return (<>
        <div style={{fontSize: "1.1rem"}}>
            <div>
                <strong>
                Since we will be collecting your audio sample, you need to provide the 
                <span style={{ color: "red"}}> microphone access </span> 
                to this website.</strong> Note: We recommend you to attempt this part of the form in 
                <span style={{ color: "red"}}> Google Chrome browser </span> 
                (latest version) on a <span style={{ color: "red"}}>desktop/laptop</span>.
            </div>
        </div>
        <br/>
        {
            (permissionDenied) ?
            (<>
                <div style={{textAlign: "center", fontWeight: "bold", fontSize: "1.2rem"}}>
                    How to allow microphone access in Chrome?
                </div>
                <div style={{fontSize: "1.1rem", marginBottom: "10px"}}>
                    Your browser has <strong style={{color: "red"}}>blocked</strong> this website from getting the microphone access. 
                    As stated before audio assessment is a mandatory step in your application and mic access is required for it. 
                    <strong> Please go through the following video to enable the access.</strong>
                </div>
                <div style={{textAlign: "center"}}>
                    <video className="permission-video" width="400" controls>
                        <source src="https://dev-qrate-storage.s3.ap-south-1.amazonaws.com/extra/Screen+Recording+2023-06-03+at+7.02.05+AM.mp4" type="video/mp4"/>
                        Your browser does not support HTML video.
                    </video>
                </div>
            </>) : (<>
                <div style={{textAlign: "center", fontWeight: "bold", fontSize: "1.2rem"}}>
                    How to allow microphone access in Chrome?
                </div>
                <div style={{fontSize: "1.1rem", marginBottom: "10px"}}>
                    Click on the "<strong style={{color: "red"}}>Allow Access</strong>" button below, and you will see a prompt
                    from your browser about Microphone access. Click <strong>Allow</strong> to 
                    give this website the microphone access.
                </div>
                <div style={{textAlign: "center"}}>
                    <Tooltip placement="right" title="Preview">
                        <Image
                            preview={{ imageVisible: false }}
                            // width="100%"
                            src={micAllow}
                            // onClick={() => setImageVisible(true)}
                            style={{ cursor: "pointer", maxWidth: "500px" }}
                        />
                    </Tooltip>
                </div>
            </>)
        }
    </>);
}


export default function WelcomeModal({ reportToMixpanel, showWelcomeModal, setShowWelcomeModal }) {
    const [current, setCurrent] = useState(0);
    const [permissionDenied, setPermissionDenied] = useState(false);

    // Fetch data from query params
    if (window?.location?.search) {
        let searchParams = new URLSearchParams(window.location.search);

        if (showWelcomeModal && searchParams.get("skip_welcome") === "yes")
            setShowWelcomeModal(false);
    }

    const getMicPermission = () => {
        trackUser("Microphone access requested in Modal", "Requesting for microphone access in modal");
        
        navigator.mediaDevices.getUserMedia({
            audio: true
        }).then(async function(stream) {
            reportToMixpanel("Success Mic Permission");
            setShowWelcomeModal(false);
            trackUser("Microphone access granted in Modal", "Granted microphone access in modal");
        },
        async function(error) {
            console.log("Error occured while giving permission!", error);
            window.alert("It seems like your have rejected mic permission. As mentioned before audio assessment is a mandatory step. Hence, Mic permission is required.");
            setPermissionDenied(true);
            trackUser("Microphone access denied in Modal", "Denied microphone access in modal");

            reportToMixpanel("Error in Mic Permission");
        });
    }

    const steps = [
        {
            title: 'Step 1',
            content: (props) => <Step1 {...props} />,
            btn: "Next"
        },
        {
            title: 'Step 2',
            content: (props) => <Step2 {...props} />,
            btn: "Next"
        },
        {
            title: 'Step 3',
            content: (props) => <Step3 {...props} />,
            btn: "Next"
        },
        {
            title: 'Step 4',
            content: (props) => <Step4 {...props} />,
            btn: "Next"
        },
        {
            title: 'Step 5',
            content: (props) => <Step5 {...props} />,
            btn: "Allow Mic Access and start assessment"
        },
    ];

    const next = () => {
        console.log(current);
        if (current < steps.length-1) {
            setCurrent(current + 1);
        } else {
            getMicPermission();
        }
    };

    return (
        <Modal
        open={showWelcomeModal}
        title={
            <strong className="welcome-modal-heading">
                Audio Screening Round
            </strong>
        }
        className="welcome-modal"
        style={{top: "10"}}
        width={800}
        closable={false}
        footer={
            <div className="welcome-modal-footer">
                <Button onClick={next} type="primary" style={{textWrap: "wrap"}}>
                    {steps[current]?.btn}
                </Button>
            </div>
        }
        >
            <div className="welcome-modal-body">
                {steps[current]?.content({onclick: next, permissionDenied: permissionDenied})}
            </div>
        </Modal>
    );
}


