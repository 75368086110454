// troubleshoot

import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Collapse, Button, Input, Form, notification } from "antd";
import { candidateIssue } from "../../../services/candidateFormApi";

const { Panel } = Collapse;

const FeedbackModal = () => {
  const panelData = [
    {
      header: "Recording button not working ?",
      content:
        "Make sure you have given the microphone access properly. Reload this page and follow the steps given in the welcome message carefully.",
    },
    {
      header: "Stop recording button is not working ?",
      content:
        "Your response should be longer then 20 seconds and therefore, stop recording button stays disabled until 20 seconds are completed.",
    },
    {
      header: "Voice is not getting recorded properly, disturbance issues ?",
      content:
        "Such issue can be happening because the microphone of your device is not working properly. Try using a different device to submit the response.",
    }
  ];
  return (
    <Collapse bordered={false}>
        {panelData.map(({ header, content, index }) => (
          <Panel header={header} key={header + index}>
            <p>{content}</p>
          </Panel>
        ))}
    </Collapse>);
}

const IssueModal = ({ modalVisible, setModalVisible, responseCandidateId }) => {
  const [issue, setIssue] = useState("");
  const [issueSubmitted, setIssueSubmitted] = useState(false);

  const handleCancel = () => {
    setModalVisible(false);
  };

  const issueTexts = {
    inactivity:
      "It seems like you have spend too long on this page. In case you are having some \
       technical issues with the website while submitting your response, please report \
       it below, otherwise click on 'I am not facing any issue' button below to continue with the form.",
    multiple_click:
      "Are you having some technical issues with the website while submitting your response? \
       If yes, please report it below, otherwise click on 'I am not facing any issue' button \
       below to continue with the form.",
    try_to_quit:
      "Please note that incomplete applications are NOT taken forwarded to next stage of \
       interview process, so make sure to answer all the questions. In case you are having \
       any technical issues with the website, please report them below.",
  };

  const search = useLocation().search;

  const getQuery = (key, isInt = false) => {
    let val = new URLSearchParams(search).get(key);
    return isInt ? (val ? parseInt(val) : null) : val;
  };

  const handleSubmit = async () => {
    let extra_data = {
      candidate_id: getQuery("candidate_id", true),
      form_id: getQuery("form_id", true),
      job_id: getQuery("job_id", true),
      ruid: getQuery("ruid"),
      field_id: getQuery("field_id", true),
      fieldset_id: getQuery("fieldset_id", true),
      job_slug: getQuery("job_slug"),
    };

    const data = {
      message: issue,
      candidate: responseCandidateId ? responseCandidateId : null,
      extra_data: extra_data
    };
    const res = await candidateIssue(data);
    if (res) {
      notification["success"]({
        message: "Thanks for reporting the issue.",
        description: "We will get back to you as soon as possible.",
      });
      // setModalVisible({ visible: false, issue: "" });
      setIssueSubmitted(true)
    }
  };

  return (
    <>
      <Modal
        title="Are you facing any issues?"
        visible={modalVisible.visible}
        onCancel={handleCancel}
        footer={(!issueSubmitted) ? [
          <Button key="cancel" onClick={handleCancel}>
            I am not facing any issue
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit issue
          </Button>,
        ] : [
          <Button key="submit" type="primary" onClick={() => setModalVisible({ visible: false, issue: "" })}>
            OK
          </Button>]}
      >
        {
          (!issueSubmitted) ? 
            (<>
              <p>{issueTexts[modalVisible.issue]}</p>
              <FeedbackModal issue={issue} setIssue={setIssue} />
              <p>
                Note: Try to explain the issue as clearly has possible as it will immensely help
                us understand and fix the issue. Please do mention your <strong>email, name and contact number</strong> 
                &nbsp;so that we can inform you as soon as the issue is fixed.
              </p>
              <Form>
                <Input.TextArea
                  rows={4}
                  placeholder="Please explain your issue in details and also mention from email, name and contact info, along with your issue.."
                  value={issue}
                  onChange={(e) => setIssue(e.target.value)}
                />
              </Form>
              <br />
            </>) : (<>
              <p>Thanks for submiting your Feedback. We will try to get it fixed as soon as possible.</p>
              <p>In the meantime, we will be conducting your audio assessment on an automated phone call. 
                You will be receiving a phone call from <strong>08069458485</strong> number, 
                please listen to the instructions carefully and complete the audio assessment.
              </p>
            </>)
        }
      </Modal>
    </>
  );
};

export default IssueModal;
