import React, { useState, useEffect, useRef } from 'react';
import "../../styles/countDown.css";

export default function CustomCountDown({ currentValue, maxValue, uploadingFile }) {
    const timerPie = useRef();
    
    // const PrimaryColor = "rgb(236, 54, 107)";
    // const SecondaryColor = "rgb(254, 239, 244)";

    const FinalCall = 20;
    const PrimaryColor = "rgb(34, 213, 201)";
    const SecondaryColor = "#eee";
    const AltColor = "rgb(255, 74, 74)";
    const SuccessColor = "#00DFA2"

    useEffect(() => {
        /**
         * - First Half: A => 90, B => 90 to 270
         * - Second Half: A => from -90 to 90 b => 270
         */

        let ratio = currentValue/maxValue;
        let mainColor = (currentValue <= FinalCall) ? AltColor:PrimaryColor;

        if (uploadingFile) {
            timerPie.current.style.background = SuccessColor;
            return;
        }

        if (ratio <= 0.5) {
            let angle1 = 90;
            // (270 - 90) * 2 = 360
            let angle2 = 90 + 360 * ratio;
            timerPie.current.style.background = `linear-gradient(${angle1}deg, ${SecondaryColor} 50%, transparent 50%, transparent), linear-gradient(${angle2}deg, ${mainColor} 50%, ${SecondaryColor} 50%, ${SecondaryColor})`;

        } else {
            let angle1 = -90 + 360 * (ratio - 0.5);
            let angle2 = 270;
            timerPie.current.style.background = `linear-gradient(${angle1}deg, ${mainColor} 50%, transparent 50%, transparent), linear-gradient(${angle2}deg, ${mainColor} 50%, ${SecondaryColor} 50%, ${SecondaryColor})`;
        }

    }, [currentValue]);

    return (
        <div className='countdown-container'>
            <div className='countdown-outer' ref={timerPie}>
                <div className={'countdown-inner' + ((uploadingFile) ? (' success') : ((currentValue <= FinalCall && currentValue != 0) ? ' urgent': ""))}>
                    {
                        (!uploadingFile) ? (
                            <>
                            <span style={{height: "inherit"}}>{currentValue}</span>
                            <div style={{fontSize: "0.8rem", textAlign: "center"}}>secs left</div>
                            </>) : (
                            <>
                            <div style={{fontSize: "1.1rem"}}>Uploading</div>
                            </>)
                    }
                </div>
            </div>

        </div>
    );
}
