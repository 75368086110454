import React, { useState, useEffect, useRef } from "react";

export default function IssueTracker({ setOpenIssueModal, isRecording, issueOccured, setIssueOccured }) {

    // Component tracks users behaviour and in case it detects that the user is:
    // - inactive on the page for more than 20sec [Inactive means the recording is not started]
    // - has clicked the start recording button more than 5 times.
    // - more than 10 clicks before starting audio recording.

    const maxClicks = 10, maxStartRecClicks = 5, maxInactiveTime = 40000;
    const inactiveTimer = useRef();
    const [clickCount, setClickCount] = useState(0);

    // Track inactivity
    useEffect(() => {
        if (issueOccured) return;
        // For creating a timeout for inactivity
        inactiveTimer.current = setTimeout(() => {
            console.log("inactivity found");
            setOpenIssueModal({ visible: true, issue: "inactivity" });
            setIssueOccured(true);
        }, maxInactiveTime);
    
        return () => {
            clearTimeout(inactiveTimer.current);
        };
    }, []);

    useEffect(() => {
        try {
            if (isRecording) {
                clearTimeout(inactiveTimer.current);
            }
        } catch(err) {
            console.log(err);
        }
    }, [isRecording]);

    // Track Clicks
    useEffect(() => {
        window.addEventListener("click", function (event){
            console.log("Click listener");
            if (issueOccured) return;
            setClickCount(clickCount + 1);
            console.log("Click listener Done");
        });
    }, []);
    

    useEffect(() => {
        if (issueOccured) return;
        if (clickCount > maxClicks) {
            console.log("maxClicks reached");
            setOpenIssueModal({ visible: true, issue: "multiple_click" });
            setIssueOccured(true);
        }
        console.log("Max Click block called!!");
    }, [clickCount]);


    // Track user quit operation
    // useEffect(() => {
    //     window.addEventListener('beforeunload', alertUser)
    //     return () => {
    //         window.removeEventListener('beforeunload', alertUser)
    //     }
    // }, [])
    // const alertUser = e => {
    //     e.preventDefault()
    //     e.returnValue = ''
    // }
    
    return <></>;
}
