import React, { useEffect, useState } from "react";
import AudioResponse from "../components/AudioResponse/AudioResponse";
import { Progress } from "antd";
import { useLocation } from "react-router-dom";
import "../styles/formField.css";
import mixpanel from "mixpanel-browser";

import aviateLogo from "../assets/aviate-logo.png";
import qrateLogo from "../assets/qrate-logo.jpg";
import { isEmpty } from "../utils/functions";

function FormField() {
  const search = useLocation().search;

  const [formData, setFormData] = useState({});

  const getQuery = (key, isInt = false) => {
    let val = new URLSearchParams(search).get(key);
    return isInt ? (val ? parseInt(val) : null) : val;
  };
  useEffect(() => {
    let data = {
      candidateId: getQuery("candidate_id", true),
      formId: getQuery("form_id", true),
      jobId: getQuery("job_id", true),
      ruId: getQuery("ruid"),
      fieldId: getQuery("field_id", true),
      fieldsetId: getQuery("fieldset_id", true),
      jobSlug: getQuery("job_slug"),
    };
    setFormData(data);
  }, [search]);

  useEffect(() => {
    // tracking users visiting the forms
    if (isEmpty(formData) || !formData?.formId || !formData?.fieldId) return;

    const mixdata = {
      tag: "[Question Viewed]",
      message: "Question Viewed in microservice page.",
      extra_data: {
        url: window.location.href,
        page_title: document.title,
      },
      candidate: formData?.candidateId ? formData?.candidateId : null,
      form: formData?.formId ? formData.formId : null,
      field: formData?.fieldId ? formData?.fieldId : null,
    };
    try {
      mixpanel.track("Question Viewed", {
        data: mixdata,
        SearchParams: Object.fromEntries(
          new URLSearchParams(window.location.search)
        ),
      });
    } catch (error) {
      console.log(error);
    }
  }, [formData]);

  return (
    <div className="section">
      {formData?.formId === 11 ? (
        <img
          src={qrateLogo}
          alt=""
          className="qrate-logo"
          style={{ width: "6rem" }}
        />
      ) : (
        <img src={aviateLogo} alt="" className="qrate-logo-old" />
      )}
      {/* <div className="progress_bar">
        <Progress
          status="active"
          percent={40} //steps[currentStep]?.progress}
          strokeColor="#52c41a"
          strokeWidth={10}
        />
      </div> */}
      <div className="form-details">
        {/* {jobData?.employer_logo && (
          <img
            src={`${process.env.NEXT_PUBLIC_FE_FILE_URL}${jobData?.employer_logo}`}
            alt=""
            className="form-employer-logo"
          />
        )} */}
        <div className="form-name">
          Registration Form
          {/* {formId == 10
            ? "HR Program Admission Form"
            : jobData
            ? `${jobData?.employer} Registration Form`
            : "Registration Form"} */}
        </div>
      </div>

      <AudioResponse data={formData} setData={setFormData} />
    </div>
  );
}

export default FormField;
