import React, { useEffect, useState } from "react";
import { Modal, Button, Image, Tooltip, Card } from "antd";
import micAllow from "../../../assets/mic-allow.png";
import micBlock1 from "../../../assets/mic-block1.png";
import micBlock2 from "../../../assets/mic-block2.png";
import micBlock3 from "../../../assets/mic-block3.png";
import micBlock4 from "../../../assets/mic-block4.png";
import { toMilliseconds } from "../../../utils/functions";
import "../../../styles/customModal.css";

const CustomModal = ({ modalNo }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [activeTabKey1, setActiveTabKey1] = useState("step1");
  const [imageVisible, setImageVisible] = useState(false);

  const intervalBtwModalShowing = toMilliseconds(0, 10, 0); //(hours,minutes,seconds)

  useEffect(() => {
    if (modalNo === 3) {
      let pastShownTime = localStorage.getItem("last_modal_shown_time");
      let lastTime = new Date(pastShownTime);
      let currTime = new Date();
      if (currTime.getTime() - lastTime.getTime() > intervalBtwModalShowing) {
        setModalVisible(true);
        localStorage.setItem("last_modal_shown_time", currTime.toString());
      }
    } else setModalVisible(true);
  }, []);

  const handleOk = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setModalVisible(false);
    } catch (error) {
      console.log(error);
      // message.error(error);
      window.alert("It seems like you didn't allow the microphone access when prompted. We want to inform you that the audio screening is a mandatory step for your application and microphone access is required for it.");
    }
  };

  const handleReload = () => {
    setModalVisible(false);
    window.location.reload(false);
  };

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  const footerBtn = {
    1: {
      label: "Allow Access",
      onclick: handleOk,
    },
    2: {
      label: "Reload",
      onclick: handleReload,
    },
    3: {
      label: "Ok",
      onclick: handleOk,
    },
  };

  const cardDetails = {
    1: {
      required: true,
      title: "How to allow microphone access in Chrome?",
    },
    2: {
      required: true,
      title:
        "The browser has blocked this website from accessing the microphone, please follow the given steps to resolve this issue.",
    },
    3: {
      required: false,
    },
  };

  return (
    <>
      <Modal
        title={
          <strong style={{ color: "#1890ff", display: "block", textAlign: "center" }}>
            Welcome to Audio Screening Round
          </strong>
        }
        visible={modalVisible}
        onOk={modalNo === 2 ? null : handleOk}
        closable={false}
        maskClosable={false}
        footer={
          (null,
          (
            <Button type="primary" onClick={footerBtn[modalNo].onclick} className="footerBtn">
              {footerBtn[modalNo].label}
            </Button>
          ))
        }
      >
        <p style={{ padding: "0 24px" }}>
          <strong>In this round you'd need to submit the audio response.</strong> Please note that
          this is a mandatory step and your application won't be considered
          without your audio responses.
          <br />
          <span style={{ color: "red" }}>
            We recommend you to attempt this part of the form in chrome browser 
            (latest version) on a desktop/laptop.
          </span>
        </p>

        {cardDetails[modalNo].required && (
          <>
          <p style={{ padding: "0 24px", fontSize: "1.1rem", fontWeight: "bold", marginBottom: "5px" }}>
            {cardDetails[modalNo].title}
          </p>
          {
            modalNo === 1 ? (
              <>
              <p>
                Click on the <strong>"Allow Access"</strong> button below, and you will see a prompt
                from your browser about Microphone access. Click <strong>Allow</strong> to 
                give this website the microphone access.
              </p>
              <div style={{padding: "10px"}}>
                <Tooltip placement="right" title="Preview">
                  <Image
                    preview={{ imageVisible: false }}
                    width="100%"
                    src={micAllow}
                    onClick={() => setImageVisible(true)}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
              </>
            ) : (
              <Card
                style={{ width: "100%" }}
                // title={cardDetails[modalNo].title}
                tabList={tabList(modalNo)}
                activeTabKey={activeTabKey1}
                onTabChange={(key) => {
                  onTab1Change(key);
                }}
              >
                {contentList(setImageVisible, modalNo)[activeTabKey1]}
              </Card>
            )
          }
          </>
        )}
      </Modal>
    </>
  );
};

export default CustomModal;

const contentList = (setImageVisible, modalNo) => {
  switch (modalNo) {
    case 1:
      return {
        step1: (
          <>
            <p>
              You will see the following prompt when you click on
              <span style={{ color: "#1890ff" }}> Start Recording</span> button.
            </p>
            <Tooltip placement="right" title="Preview">
              <Image
                preview={{ imageVisible: false }}
                width="60%"
                src={micAllow}
                onClick={() => setImageVisible(true)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
            <p style={{ paddingTop: "12px" }}>
              Click on <span style={{ color: "#1890ff" }}>Allow</span> in the
              prompt.
            </p>
          </>
        ),
      };
    case 2:
      return {
        step1: (
          <>
            <p>
              In your browser, click on the 3 dots present on the top-right
              corner and then click on{" "}
              <span style={{ color: "#1890ff" }}>Settings</span>.
            </p>
            <Tooltip placement="right" title="Preview">
              <Image
                preview={{ imageVisible: false }}
                width="50%"
                src={micBlock1}
                onClick={() => setImageVisible(true)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </>
        ),
        step2: (
          <>
            <p>
              On the Settings page, click on{" "}
              <span style={{ color: "#1890ff" }}>Security and Privacy</span> and
              then click on{" "}
              <span style={{ color: "#1890ff" }}>Site Settings</span>.
            </p>
            <Tooltip placement="right" title="Preview">
              <Image
                preview={{ imageVisible: false }}
                width="80%"
                src={micBlock2}
                onClick={() => setImageVisible(true)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </>
        ),
        step3: (
          <>
            <p>
              On the site settings page, click on{" "}
              <span style={{ color: "#1890ff" }}>Microphone</span>
            </p>
            <Tooltip placement="right" title="Preview">
              <Image
                preview={{ imageVisible: false }}
                width="80%"
                src={micBlock3}
                onClick={() => setImageVisible(true)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </>
        ),
        step4: (
          <>
            <p>
              Under{" "}
              <span style={{ color: "#1890ff" }}>
                Not allowed to use your microphone
              </span>
              , find
              <span style={{ color: "#1890ff" }}>
                {" "}
                https://dev-app.aviate.jobs
              </span>{" "} or
              <span style={{ color: "#1890ff" }}>
                {" "}
                https://dev-app.qrate.ai
              </span>{" "}
              and click on the bin icon to remove the block.
            </p>
            <Tooltip placement="right" title="Preview">
              <Image
                preview={{ imageVisible: false }}
                width="80%"
                src={micBlock4}
                onClick={() => setImageVisible(true)}
                style={{ cursor: "pointer", overflow: "hidden" }}
              />
            </Tooltip>
          </>
        ),
        step5: (
          <p>Click on the button below to Reload the website and try again.</p>
        ),
      };
    default:
      return null;
  }
};

const tabList = (modalNo) => {
  switch (modalNo) {
    case 1:
      return [
        {
          key: "step1",
          tab: "Step 1",
        },
      ];
    case 2:
      return [
        {
          key: "step1",
          tab: "Step 1",
        },
        {
          key: "step2",
          tab: "Step 2",
        },
        {
          key: "step3",
          tab: "Step 3",
        },
        {
          key: "step4",
          tab: "Step 4",
        },
        {
          key: "step5",
          tab: "Step 5",
        },
      ];
    default:
      return null;
  }
};
