import React, { useState, useEffect } from "react";
import Icon, { CaretRightFilled } from "@ant-design/icons";
// import { candidateIssue } from "../../../services/candidateFormApi";

export default function Question({ questionData }) {

    return (
        <>
            <div className="question_label" style={{display: "flex"}}>
                <Icon component={CaretRightFilled} style={{lineHeight: "1.9"}} />
                <span>{questionData?.label}</span>
            </div>
            {
                questionData?.sub_text && 
                <div className="question_sub_text">
                    {questionData?.sub_text}
                </div>
            }
        </>
    )
}
