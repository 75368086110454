export const getDuration = (fieldId) => {
  switch (fieldId) {
    case 639:
      return { min: 120, max: 180 };
    case 690:
      return { min: 120, max: 180 };
    case 727:
      return { min: 60, max: 180 };
    case 728:
      return { min: 60, max: 180 };
    case 23:
      return { min: 5, max: 30 };
    default:
      return { min: 20, max: 120 };
  }
};
