import React, { useEffect, useState } from "react";
import { Button, Tooltip, message } from "antd";
import { TimeToWords } from "../../utils/functions";
import { getDuration } from "./minMaxDurations";

const ButtonControls = ({
  isRecording,
  timer,
  handleStartRecording,
  handleStopRecording,
  showReportModal,
  data,
}) => {
  const [duration, setDuration] = useState({
    min: 20,
    max: 120,
  });

  useEffect(() => {
    let dur = getDuration(data?.fieldId);
    setDuration({
      min: dur.min,
      max: dur.max,
    });
  }, [data?.fieldId]);

  const warningTime = 15; //15 sec
  const warningMessage = `The recording will be automatically submitted when timer reaches ${TimeToWords(
    duration.max
  )}. Answer the question accordingly.`;

  useEffect(() => {
    if (timer >= duration.max) handleStopRecording();
    if (timer === duration.max - warningTime)
      message.warn({
        content: warningMessage,
        duration: warningTime,
        style: {
          maxWidth: "400px",
          marginLeft: "auto",
          marginTop: "20px",
        },
      });
  }, [timer]);

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      {isRecording ? (
        <Tooltip
          title={
            timer <= duration.min
              ? `Answer needs to be longer than ${TimeToWords(
                  duration.min
                )}!`
              : null
          }
          trigger={["hover", "click"]}
        >
          <Button
            id="stop-recording-btn"
            onClick={() => handleStopRecording()}
            disabled={timer <= duration.min}
            size="large"
          >
            Stop Recording
          </Button>
        </Tooltip>
      ) : (
        <Button
          id="recording-btn"
          onClick={() => handleStartRecording()}
          // disabled={isDisabled}
          //   loading={stopLoading}
          size="large"
        >
          Start Recording
        </Button>
      )}
      <Button
        id="troubleshoot_btn_audio"
        danger
        ghost
        onClick={() => {
          showReportModal();
        }}
        size="large"
      >
        Facing issues?
      </Button>
    </div>
  );
};

export default ButtonControls;
