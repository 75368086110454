import React, { useEffect, useRef, useState } from "react";
import { Button, Tooltip, Modal } from "antd";
import RecordRTC from "recordrtc";
import Timer from "./Timer";
import { visualize } from "./Visualizer";
import "../../styles/audioForm.css";
import { trackUser } from "../../services/candidateFormApi";
import PreRecordTimer from "./PreRecordTimer";
import CustomCountDown from "./CustomCountDown";

export default function AudioRecorder({ 
    setAudioBlog, 
    uploadingFile, 
    setOpenIssueModal, 
    isRecording, 
    setIsRecording, 
    reportToMixpanel, 
    showWelcomeModal,
    minResponseTime,
    maxResponseTime,
    prestartTimer }) {
    const [recorder, setRecorder] = useState(null);
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);

    // Countdown Timer
    const startTime = maxResponseTime || 60; // start from 60 sec

    const intervalRef = useRef(null);
    const counterRef = useRef(startTime);

    const [timer, setTimer] = useState(startTime);

    useEffect(() => {
        if (!isRecording) return;
        if (intervalRef.current) return;

        intervalRef.current = setInterval(function printTime(){
            console.log(counterRef.current);
            counterRef.current -= 1;
        
            setTimer(counterRef.current);

            const minResponseCountdown = maxResponseTime - minResponseTime;

            if (counterRef.current <= minResponseCountdown) {
                setCanStopRecording(true);
            }
        
            if (counterRef.current <= 0) {
                clearInterval(intervalRef.current);

                // XXX: When timer hits ZERO
                stopRecording();
            }
        }, 1000);
    }, [isRecording]);

    const startRecording = () => {
        trackUser("Microphone access requested", "Requesting for microphone access");

        navigator.mediaDevices.getUserMedia({
            audio: true
        }).then(async function(stream) {
            trackUser("Microphone access granted", "Granted microphone access");

            let recorder = RecordRTC(stream, {
                type: 'audio'
            });
            setRecorder(recorder);

            recorder.startRecording();
            setIsRecording(true);

            reportToMixpanel("Success Mic Permission");
        },
        async function(error) {
            trackUser("Microphone access denied", "Denied microphone access");

            console.log("Error occured while giving permission!", error);
            setPermissionDenied(true);

            reportToMixpanel("Error in Mic Permission");
        });   
    }

    const stopRecording = () => {
        console.log("Stop recording called", canStopRecording);
        // if (!canStopRecording) return;

        trackUser("Stop recording clicked", "Stop recording clicked");
        
        recorder.stopRecording(function() {
            let blob = recorder.getBlob();
            console.log(blob);

            trackUser("Recording Stopped", "Recording Stopped");
            setAudioBlog(blob);
            setIsRecording(false);
        });
    }

    const issueBtnClick = () => {
        trackUser("Help Button Clicked", "Help Button Clicked");
        setOpenIssueModal({ visible: true });
    }

    return (
        <>
            <div className="audio-recorder">
                {
                    (isRecording || uploadingFile) ? 
                        (
                            <>
                                <h3 className="recorder-header-text">Start Speaking</h3>
                                <CustomCountDown 
                                    currentValue={timer} 
                                    maxValue={startTime}
                                    uploadingFile={uploadingFile}
                                />
                                <div className="recorder-text-container">
                                    <div className="recorder-text"></div>
                                    You are being recorded
                                </div>

                                <div className="button-collection">
                                    <Button 
                                        type="primary" danger 
                                        onClick={stopRecording} 
                                        disabled={!canStopRecording || uploadingFile}>
                                        {uploadingFile && <span>(Uploading) </span>}Stop Recording
                                    </Button>
                                    <Button onClick={issueBtnClick} danger>
                                        Having issue?
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <PreRecordTimer startRecording={startRecording} showWelcomeModal={showWelcomeModal} prestartTimer={prestartTimer} />
                        )
                }
            </div>
            <h3 style={{fontWeight: "bold", padding: "20px", textAlign: "center"}}>
                DON'T <span style={{color: "red"}}>MINIMIZE THIS WINDOW, RELOAD THIS PAGE, SWITCH THIS TAB or CLOSE THIS PAGE</span>,
                OTHERWISE YOUR INTERVIEW REGISTRATION AND SPEECH ASSESSMENT MIGHT GET 
                <span style={{color: "red"}}> REJECTED</span>.
            </h3>
            <MicAccessModal permissionDenied={permissionDenied} />
        </>
    );
}

function MicAccessModal({permissionDenied}) {
    
    useEffect(() => {
        if (!permissionDenied) return;

        var location = window.location;

        var url = new URL(location.origin + location.pathname);
        var newParams = new URLSearchParams(location.search);;

        newParams.set("skip_welcome", "no");
        console.log(newParams);
        url.search = new URLSearchParams(newParams);

        var nextUrl = url.toString();
        window.location.replace(nextUrl);
    }, [permissionDenied])

    return (
        <Modal title="Couldn't get microphone access" open={permissionDenied}>
            It seems like your browser has blocked the website from getting the
            microphone access. This is a mandatory step of your Registration
            process and we won't be able to move ahead without this.
            <br/><br/>
            Please follow the steps mentioned in the video properly to allow
            allow mic access for this website. In case the problem still
            persists, use a different device.
        </Modal>
    );
}
