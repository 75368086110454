import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { trackUser, unloadListener } from './services/candidateFormApi';

const root = ReactDOM.createRoot(document.getElementById("root"));

// Track user's visit
trackUser("Page Visit", "Page Visit audio form")

// Prevent users from simply closing the page, also track it when this happens
window.addEventListener('beforeunload', unloadListener);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
